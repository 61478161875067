import React, { useState, useEffect } from "react";
import "../component/style.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";

const Tempapp = () => {
  const [city, setCity] = useState(null);
  const [time, setTime] = useState("");
  const [search, setSearch] = useState("New York City");

  useEffect(() => {
    const fetchApi = async () => {
      const url = `https://api.weatherapi.com/v1/current.json?key=41533b7315214268b54104730232801&q=${search}`;
      const res = await fetch(url);
      const resJson = await res.json();
      setCity(resJson.current);
      setTime(resJson.location);
    };

    fetchApi();
  }, [search]);

  const inputEvent = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <div className="album py-5">
        <div className="container">
          <h1 className="main_heading">Wheather Report</h1>
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col">
              <div className="card">
                <input
                  type="search"
                  placeholder="Search Anyplace Name"
                  onChange={inputEvent}
                />
                {!city ? (
                  <p>No Data Found</p>
                ) : (
                  <div>
                    <div className="card_main">
                      <img
                        className="main_img"
                        src={city.condition.icon}
                        alt={city.condition.icon}
                      />
                      <h3 className="text-center">{city.condition.text}</h3>
                      <h2 className="text-center">
                        {search}, {time.country}
                      </h2>
                    </div>
                    <div className="card-body">
                      <select className="select text-center">
                        <option value="otherOption">{city.temp_f}°F</option>
                        <option value="someOption">{city.temp_c}°C</option>
                      </select>
                      {/* <h1 className="text-center">{city.temp_c}°C</h1> */}
                      <p className="card-text"></p>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="btn-group">
                          <button type="button" className="btn">
                            Humidity: {city.humidity}%
                          </button>
                        </div>
                        <div className="btn-group">
                          <button type="button" className="btn">
                            Time: {time.localtime}
                          </button>
                        </div>
                        <div className="btn-group">
                          <select className="select text-center">
                            <option value="someOption">
                              Wind_mph: {city.wind_mph}
                            </option>
                            <option value="otherOption">
                              Wind_kph: {city.wind_kph}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tempapp;
